import { useContext } from 'react'

import { type SanityBlockLinkPage } from '@data/sanity/queries/types/link'
import { isCollectionPageType, isProductCategoryPageType } from '@lib/routes'
import { SiteContext } from '@lib/site-context'

interface LinkCountValueProps {
  page?: SanityBlockLinkPage
  showCollectionCount?: boolean
  showProductCategoryCount?: boolean
}

const LinkProductCount = ({
  page,
  showCollectionCount,
  showProductCategoryCount,
}: LinkCountValueProps) => {
  const {
    allProductCount,
    getCollectionProductCount,
    getCategoryProductCount,
  } = useContext(SiteContext)

  const pageType = page?._type
  const pageSlug = page?.slug?.current

  if (!pageType) {
    return null
  }

  const isCollection = isCollectionPageType(pageType)
  const isProductCategory = isProductCategoryPageType(pageType)

  if (
    (isCollection && !showCollectionCount) ||
    (isProductCategory && !showProductCategoryCount)
  ) {
    return null
  }

  let productCount: number | undefined

  if (isCollection) {
    productCount = pageSlug
      ? getCollectionProductCount(pageSlug)
      : allProductCount
  }

  if (!isCollection && isProductCategory) {
    productCount = getCategoryProductCount(pageSlug)
  }

  if (typeof productCount === 'undefined') {
    return null
  }

  return (
    <span
      aria-hidden="true"
      className="inline-block relative ml-2 leading-none align-super text-[.5em]"
    >
      {productCount}
    </span>
  )
}

export default LinkProductCount
