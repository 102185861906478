import cx from 'classnames'

export type IconName =
  | 'Cart'
  | 'Check'
  | 'ChevronDown'
  | 'Close'
  | 'Cross'
  | 'Dispatcher'
  | 'Globe'
  | 'Mail'
  | 'Minus'
  | 'Plus'
  | 'Search'
  | 'Spinner'
  | 'User'

interface IconProps {
  id: string
  name: IconName
  color?: string
  viewBox?: string
  className?: string
}

const Icon = ({ id, name, color, viewBox, className }: IconProps) => {
  const defaultViewBox = getDefaultViewBox(name)

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ? viewBox : defaultViewBox}
      aria-labelledby={`${name.replace(/\s/g, '').toLowerCase()}-${id}`}
      className={cx(className, 'w-[1em] h-[1em]')}
    >
      <title id={`${name.replace(/\s/g, '').toLowerCase()}-${id}`}>
        {name}
      </title>
      {getIcon(name, color)}
    </svg>
  )
}

const getIcon = (name: IconName, color?: string) => {
  switch (name) {
    case 'Cart':
      return (
        <>
          <path
            d="M10 12V5.61446C10 3.61825 11.7909 2 14 2C16.2091 2 18 3.61825 18 5.61446V12"
            stroke={color}
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path
            d="M22.75 8H5.25L4 25.5H24L22.75 8Z"
            stroke={color}
            strokeWidth="1.5"
            className="stroke-current"
          />
        </>
      )
    case 'Check':
      return (
        <path
          d="M22 8L11 19L6 14"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="square"
          className="stroke-current"
        />
      )
    case 'ChevronDown':
      return (
        <path
          d="M8 11L14 17L20 11"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="square"
          className="stroke-current"
        />
      )
    case 'Close':
      return (
        <>
          <path
            d="M20 8L8 20"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
            className="stroke-current"
          />
          <path
            d="M8 8L20 20"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
            className="stroke-current"
          />
        </>
      )
    case 'Cross':
      return (
        <>
          <line
            x1="11.0977"
            y1="1.90385"
            x2="1.90531"
            y2="11.0962"
            fill="none"
            stroke={color}
            className="stroke-current"
          />
          <line
            x1="1.90433"
            y1="1.90383"
            x2="11.0967"
            y2="11.0962"
            fill="none"
            stroke={color}
            className="stroke-current"
          />
        </>
      )
    case 'Dispatcher':
      return (
        <>
          <path
            d="M4.84125 11.925V9.78753C4.84125 7.88892 5.59547 6.06806 6.938 4.72553C8.28052 3.38301 10.1014 2.62878 12 2.62878C13.8986 2.62878 15.7195 3.38301 17.062 4.72553C18.4045 6.06806 19.1587 7.88892 19.1587 9.78753V11.925"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            className="stroke-current"
          />
          <path
            d="M4.5225 11.925H6.285V16.9425H4.5225C4.22413 16.9425 3.93798 16.824 3.727 16.613C3.51603 16.4021 3.3975 16.1159 3.3975 15.8175V13.05C3.3975 12.7517 3.51603 12.4655 3.727 12.2546C3.93798 12.0436 4.22413 11.925 4.5225 11.925Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            className="stroke-current"
          />
          <path
            d="M19.4775 16.9425H17.715V11.925H19.4775C19.7759 11.925 20.062 12.0435 20.273 12.2545C20.484 12.4655 20.6025 12.7516 20.6025 13.05V15.8175C20.6025 16.1159 20.484 16.402 20.273 16.613C20.062 16.824 19.7759 16.9425 19.4775 16.9425Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            className="stroke-current"
          />
          <path
            d="M19.3875 17.085V18.96C19.3875 19.3578 19.2295 19.7393 18.9482 20.0206C18.6669 20.3019 18.2853 20.46 17.8875 20.46H13.71"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            className="stroke-current"
          />
          <path
            d="M12.9562 19.47H11.4187C11.0045 19.47 10.6687 19.8058 10.6687 20.22V20.6212C10.6687 21.0354 11.0045 21.3712 11.4187 21.3712H12.9562C13.3705 21.3712 13.7062 21.0354 13.7062 20.6212V20.22C13.7062 19.8058 13.3705 19.47 12.9562 19.47Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            className="stroke-current"
          />
        </>
      )
    case 'Globe':
      return (
        <>
          <path
            d="M12 21.3225C17.1487 21.3225 21.3225 17.1487 21.3225 12C21.3225 6.85132 17.1487 2.67749 12 2.67749C6.85132 2.67749 2.6775 6.85132 2.6775 12C2.6775 17.1487 6.85132 21.3225 12 21.3225Z"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path
            d="M12 2.56494C13.3638 3.75645 14.4588 5.2241 15.2126 6.87071C15.9664 8.51731 16.3618 10.3053 16.3725 12.1162C16.3725 18.1874 13.6425 19.9912 12 21.2062"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path
            d="M12 2.56494C10.6342 3.75541 9.53703 5.22261 8.78126 6.86927C8.0255 8.51592 7.62838 10.3044 7.61625 12.1162C7.61625 18.1874 10.3462 19.9912 11.9887 21.2062"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path
            d="M3.88875 7.40625H20.1562"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path d="M12 2.56494V21.2062" stroke="#E9E9E9" strokeWidth="1.5" />
          <path
            d="M4.14375 16.9988H19.8675"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path
            d="M2.6775 12.1163L21.3225 11.8838"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
        </>
      )
    case 'Mail':
      return (
        <>
          <path
            d="M20.0964 5H3.90361C3.40456 5 3 5.32545 3 5.72691V17.2731C3 17.6746 3.40456 18 3.90361 18H20.0964C20.5954 18 21 17.6746 21 17.2731V5.72691C21 5.32545 20.5954 5 20.0964 5Z"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path
            d="M4 5L12.3398 13L20 5.26218"
            stroke="currentColor"
            strokeWidth="1.5"
            className="stroke-current"
          />
        </>
      )
    case 'Minus':
      return (
        <path
          d="M21 14H7.00001"
          color={color}
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
          className="stroke-current"
        />
      )
    case 'Plus':
      return (
        <>
          <path
            d="M21 14H7.00001"
            color={color}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
            className="stroke-current"
          />
          <path
            d="M14 7V21"
            color={color}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
            className="stroke-current"
          />
        </>
      )
    case 'Search':
      return (
        <>
          <path
            d="M12.5 19C16.0899 19 19 16.0899 19 12.5C19 8.91015 16.0899 6 12.5 6C8.91015 6 6 8.91015 6 12.5C6 16.0899 8.91015 19 12.5 19Z"
            color={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="stroke-current"
          />
          <path
            d="M22 22L18 18"
            color={color}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
            className="stroke-current"
          />
        </>
      )

    case 'Spinner':
      return (
        <path
          d="M50 7.617C26.594 7.617 7.617 26.594 7.617 50H0C0 22.387 22.387 0 50 0s50 22.387 50 50h-7.617C92.383 26.594 73.406 7.617 50 7.617zm0 0"
          fill={color}
          className="fill-current"
        />
      )
    case 'User':
      return (
        <>
          <path
            d="M14 13C16.2091 13 18 11.2091 18 9C18 6.79086 16.2091 5 14 5C11.7909 5 10 6.79086 10 9C10 11.2091 11.7909 13 14 13Z"
            stroke={color}
            strokeWidth="1.5"
            className="stroke-current"
          />
          <path
            d="M21 23C21 21.1435 20.2625 19.363 18.9497 18.0503C17.637 16.7375 15.8565 16 14 16C12.1435 16 10.363 16.7375 9.05025 18.0503C7.7375 19.363 7 21.1435 7 23H21Z"
            stroke={color}
            strokeWidth="1.5"
            className="stroke-current"
          />
        </>
      )
    default:
      return <path />
  }
}

const getDefaultViewBox = (iconName: IconName) => {
  switch (iconName) {
    case 'Cross':
      return '0 0 13 13'
    case 'Spinner':
      return '0 0 100 100'
    case 'Dispatcher':
      return '0 0 24 24'
    case 'Globe':
      return '0 0 24 24'
    case 'Mail':
      return '0 0 24 24'
    default:
      return '0 0 28 28'
  }
}

export default Icon
