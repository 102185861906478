import { type SanityColorValue } from '@data/sanity/queries/types/color'

export const backgroundColorClasses: Record<
  SanityColorValue,
  string | string[]
> = {
  black: 'bg-black text-white',
  white: 'bg-white text-black',
  gray: 'bg-gray-100 text-black',
}
