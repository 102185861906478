import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { useUser } from './auth'
import { CartContext } from './cart/context'
import {
  type BundleDiscount,
  type DiscountItem,
  type QuantityDiscount,
  getBundleDiscounts,
  getCartDiscountItems,
  getQuantityDiscounts,
} from './discount'

interface DiscountContextProps {
  quantityDiscounts: QuantityDiscount[]
  bundleDiscounts: BundleDiscount[]
  cartDiscountItems: DiscountItem[] | null
}

interface DiscountContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const DiscountContext = createContext<DiscountContextProps>({
  quantityDiscounts: [],
  bundleDiscounts: [],
  cartDiscountItems: null,
})

export const DiscountContextProvider = ({
  site,
  children,
}: DiscountContextProviderProps) => {
  const { cart } = useContext(CartContext)

  const { user } = useUser()

  const [cartDiscountItems, setCartDiscountItems] = useState<
    DiscountItem[] | null
  >(null)

  const quantityDiscounts = useMemo(
    () => getQuantityDiscounts(site.discounts),
    [site.discounts],
  )
  const bundleDiscounts = useMemo(
    () => getBundleDiscounts(site.discounts),
    [site.discounts],
  )

  // Calculate cart item discounts
  useEffect(() => {
    // Wait for cart to load
    if (!cart.id) {
      return
    }

    setCartDiscountItems(
      getCartDiscountItems(
        cart.lineItems,
        quantityDiscounts,
        bundleDiscounts,
        user?.company,
      ),
    )
  }, [
    bundleDiscounts,
    cart.id,
    cart.lineItems,
    quantityDiscounts,
    user?.company,
  ])

  return (
    <DiscountContext.Provider
      value={{
        quantityDiscounts,
        bundleDiscounts,
        cartDiscountItems,
      }}
    >
      {children}
    </DiscountContext.Provider>
  )
}
