import { type KeyboardEvent, useState, useContext } from 'react'
import FocusTrap from 'focus-trap-react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { type SanityCartSettings } from '@data/sanity/queries/types/cart'
import { CartContext } from '@lib/cart/context'
import { useCartItemCount } from '@lib/cart/hooks'
import { type ErrorMessages } from '@lib/helpers'
import { StringsContext } from '@lib/strings-context'

import CartToggle from '@components/cart-toggle'
import DrawerBackdrop from '@components/drawer/backdrop'
import CommentField from './comment-field'
import CartEmpty from './empty'
import CartItem from './item'
import CartSubmit from './submit'
import CartSummary from './summary'
import VatIdField from './vat-id-field'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'

interface CartModalProps {
  cartSettings?: SanityCartSettings
}

const CartModal = ({ cartSettings }: CartModalProps) => {
  const {
    cart,
    isCartOpen,
    isCartUpdating,
    isCartSubmitting,
    openCartInModal,
    toggleCart,
  } = useContext(CartContext)
  const strings = useContext(StringsContext)
  const cartItemCount = useCartItemCount()

  const [hasFocus, setHasFocus] = useState(false)
  const [vatId, setVatId] = useState('')
  const [comment, setComment] = useState('')
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  if (!openCartInModal) {
    return null
  }

  const handleKeyup = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      toggleCart(false)
    }
  }
  const handleVatIdChange = (newValue: string) => setVatId(newValue)
  const handleCommentChange = (newValue: string) => setComment(newValue)

  const lineItems = cart?.lineItems ?? []

  return (
    <>
      <FocusTrap
        active={isCartOpen && hasFocus}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <motion.div
          initial="hide"
          animate={isCartOpen ? 'show' : 'hide'}
          variants={{
            show: { x: '0%' },
            hide: { x: '100%' },
          }}
          transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
          onKeyUp={(event) => handleKeyup(event)}
          onAnimationComplete={() => setHasFocus(isCartOpen)}
          className={cx(
            'fixed top-0 right-0 w-full h-screen max-w-3xl z-90 bg-pageBG text-pageText pointer-events-none transition-visibility',
            {
              invisible: !isCartOpen,
              'pointer-events-auto transition delay-[0s]': isCartOpen,
              'cursor-wait': isCartUpdating || isCartSubmitting,
            },
          )}
        >
          <div className="flex flex-col relative h-full">
            <div className="flex items-center justify-between p-5 border-b">
              <h3>
                {strings.cartTitle} ({cartItemCount})
              </h3>

              <Button
                variant={ButtonVariant.OUTLINED}
                color={ButtonColor.GRAY}
                size={ButtonSize.XS}
                icon="Close"
                onClick={() => toggleCart(false)}
              />
            </div>

            <div className="flex-1 flex flex-col overflow-y-scroll no-scrollbar p-5">
              {lineItems.length > 0 && (
                <div className="flex flex-col gap-y-5">
                  {lineItems.map((item) => (
                    <CartItem
                      key={item.lineId}
                      item={item}
                      className="[&:last-of-type]:border-0"
                    />
                  ))}
                </div>
              )}

              {lineItems.length === 0 && <CartEmpty />}
            </div>

            {lineItems.length > 0 && (
              <div className="px-5 pb-5 border-t">
                {cartSettings?.showVatId && (
                  <VatIdField
                    inModal
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                    onChange={handleVatIdChange}
                  />
                )}

                {cartSettings?.showComment && (
                  <CommentField inModal onChange={handleCommentChange} />
                )}

                <CartSummary />

                <CartSubmit
                  className="mt-5"
                  terms={cartSettings?.terms}
                  storeUrl={cartSettings?.storeUrl}
                  setErrorMessages={setErrorMessages}
                  vatId={vatId}
                  comment={comment}
                  onClick={() => toggleCart(false)}
                />

                {cartSettings?.message && (
                  <p className="mt-4 text-center text-sm font-medium">
                    {cartSettings.message}
                  </p>
                )}
              </div>
            )}
          </div>
        </motion.div>
      </FocusTrap>

      <DrawerBackdrop isOpen={isCartOpen} onClick={() => toggleCart(false)} />
    </>
  )
}

export default CartModal
