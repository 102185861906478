import cx from 'classnames'
import { useContext } from 'react'

import { useUser } from '@lib/auth'
import { CartContext } from '@lib/cart/context'
import { useCartTotals } from '@lib/cart/hooks'
import { DiscountContext } from '@lib/discount-context'

import CartDiscount from './discount'
import CartSubtotal from './subtotal'
import CartTotal from './total'

interface CartSummaryProps {
  className?: string
}

const CartSummary = ({ className }: CartSummaryProps) => {
  const { subtotal, automaticDiscount, companyDiscount, total } =
    useCartTotals()
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)

  const { user } = useUser()

  return (
    <div className={cx(className)}>
      <CartSubtotal price={subtotal} />

      {automaticDiscount > 0 && (
        <CartDiscount
          title={cart?.automaticDiscount?.title}
          amount={automaticDiscount}
        />
      )}

      {cartDiscountItems?.map(({ id, title, amount, quantity }) => (
        <CartDiscount
          key={id}
          title={title}
          amount={amount}
          quantity={quantity}
        />
      ))}

      {companyDiscount > 0 && (
        <CartDiscount
          title={user?.company?.name ?? ''}
          amount={companyDiscount}
        />
      )}

      <CartTotal price={total} />
    </div>
  )
}

export default CartSummary
