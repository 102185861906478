import {
  productArchiveMutation,
  productCreateMutation,
  productPublishMutation,
  productVariantsBulkCreateMutation,
} from '../../data/shopify/admin/mutations/product'
import { getExpiredProductsQuery } from '../../data/shopify/admin/queries/product'
import {
  type CreateMediaInput,
  type GetExpiredProductsQuery,
  type GetExpiredProductsQueryVariables,
  type MetafieldInput,
  type ProductArchiveMutation,
  type ProductArchiveMutationVariables,
  type ProductCreateMutation,
  type ProductCreateMutationVariables,
  type ProductInput,
  type ProductPublishMutation,
  type ProductPublishMutationVariables,
  type ProductVariantsBulkCreateMutation,
  type ProductVariantsBulkCreateMutationVariables,
  type ProductVariantsBulkInput,
  MediaContentType,
  ProductVariantsBulkCreateStrategy,
  WeightUnit,
} from '../../data/shopify/admin/types'
import { ProductValidationError } from '../../lib/product'
import { type ShopifyClient } from './client'

export const shopifyFrameProductType = 'Generated Frame'

/**
 * Gets expired custom frame products from Shopify.
 */
export const getShopifyExpiredProducts = async (
  shopifyAdminClient: ShopifyClient,
  productActiveDayCount: number,
) => {
  const createdAtFilter = new Date(
    new Date().setDate(new Date().getDate() - productActiveDayCount),
  )
  const productQuery = [
    `product_type:'${shopifyFrameProductType}'`,
    `status:'ACTIVE'`,
    `created_at:<'${createdAtFilter.toISOString()}'`,
  ].join(' AND ')

  const expiredProductsResult = await shopifyAdminClient.query<
    GetExpiredProductsQuery,
    GetExpiredProductsQueryVariables
  >({
    query: getExpiredProductsQuery,
    variables: {
      productQuery,
    },
  })

  return expiredProductsResult.data.products.edges.map((edge) => ({
    id: edge.node.id,
  }))
}

/**
 * Creates a new product variant in Shopify.
 */
export const createShopifyProductVariant = async (
  shopifyAdminClient: ShopifyClient,
  productId: string,
  sku: string,
  barcode: string,
  price: number,
  weight: number,
) => {
  const productVariantsBulkInput: ProductVariantsBulkInput[] = [
    {
      optionValues: [
        {
          optionName: 'Type',
          name: 'Custom',
        },
      ],
      barcode,
      price: price.toString(),
      taxable: true,
      inventoryItem: {
        sku,
        measurement: {
          weight: {
            value: weight,
            unit: WeightUnit.Grams,
          },
        },
        requiresShipping: true,
        tracked: false,
      },
    },
  ]

  const variantsBulkCreateResult = await shopifyAdminClient.mutate<
    ProductVariantsBulkCreateMutation,
    ProductVariantsBulkCreateMutationVariables
  >({
    mutation: productVariantsBulkCreateMutation,
    variables: {
      productId,
      strategy: ProductVariantsBulkCreateStrategy.RemoveStandaloneVariant,
      variants: productVariantsBulkInput,
    },
  })
  const userErrors =
    variantsBulkCreateResult.data?.productVariantsBulkCreate?.userErrors ?? []

  if (userErrors.length > 0) {
    const userErrorMessage = userErrors
      .map((userError) =>
        !userError.field
          ? userError.message
          : `[${userError.field.join('.')}] ${userError.message}`,
      )
      .join('; ')
    throw new ProductValidationError(userErrorMessage)
  }

  return variantsBulkCreateResult.data
}

/**
 * Creates a new product in Shopify.
 */
export const createShopifyProduct = async (
  shopifyAdminClient: ShopifyClient,
  title: string,
  handle: string,
  metafields: MetafieldInput[],
  tags: string[],
  imageResourceUrl: string,
) => {
  const productInput: ProductInput = {
    productType: shopifyFrameProductType,
    title,
    handle,
    metafields,
    tags,
    productOptions: [
      {
        name: 'Type',
        values: [
          {
            name: shopifyFrameProductType,
          },
        ],
      },
    ],
  }
  const media: CreateMediaInput[] = [
    {
      mediaContentType: MediaContentType.Image,
      alt: 'Frame preview',
      originalSource: imageResourceUrl,
    },
  ]

  const productCreateResult = await shopifyAdminClient.mutate<
    ProductCreateMutation,
    ProductCreateMutationVariables
  >({
    mutation: productCreateMutation,
    variables: {
      input: productInput,
      media,
    },
  })
  const userErrors = productCreateResult.data?.productCreate?.userErrors ?? []

  if (userErrors.length > 0) {
    const userErrorMessage = userErrors
      .map((userError) =>
        !userError.field
          ? userError.message
          : `[${userError.field.join('.')}] ${userError.message}`,
      )
      .join('; ')
    throw new ProductValidationError(userErrorMessage)
  }

  return productCreateResult.data
}

/**
 * Publishes a product in Shopify.
 */
export const publishShopifyProduct = async (
  shopifyAdminClient: ShopifyClient,
  productId: string,
) => {
  const productPublishResult = await shopifyAdminClient.mutate<
    ProductPublishMutation,
    ProductPublishMutationVariables
  >({
    mutation: productPublishMutation,
    variables: {
      productId,
    },
  })
  const userErrors =
    productPublishResult.data?.publishablePublishToCurrentChannel?.userErrors ??
    []

  if (userErrors.length > 0) {
    const userErrorMessage = userErrors
      .map((userError) =>
        !userError.field
          ? userError.message
          : `[${userError.field.join('.')}] ${userError.message}`,
      )
      .join('; ')
    throw new ProductValidationError(userErrorMessage)
  }

  return productPublishResult.data
}

/**
 * Archives a product in Shopify.
 */
export const archiveShopifyProduct = async (
  shopifyAdminClient: ShopifyClient,
  productId: string,
) => {
  const productPublishResult = await shopifyAdminClient.mutate<
    ProductArchiveMutation,
    ProductArchiveMutationVariables
  >({
    mutation: productArchiveMutation,
    variables: {
      productId,
    },
  })
  const userErrors = productPublishResult.data?.productUpdate?.userErrors ?? []

  if (userErrors.length > 0) {
    const userErrorMessage = userErrors
      .map((userError) =>
        !userError.field
          ? userError.message
          : `[${userError.field.join('.')}] ${userError.message}`,
      )
      .join('; ')
    throw new Error(userErrorMessage)
  }

  return productPublishResult.data?.productUpdate?.product
}
