import { useContext } from 'react'
import cx from 'classnames'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartDiscountProps {
  title: string
  amount: number
  quantity?: number
  className?: string
}

const CartDiscount = ({
  title,
  amount,
  quantity,
  className,
}: CartDiscountProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const amountWithCurrency = getPriceWithCurrency(
    amount * (quantity ?? 1),
    currency,
    {
      taxRate,
      thousandSeparator: ',',
    },
  )

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className,
      )}
    >
      <div className="inline-flex flex-wrap gap-x-5 items-center">
        {strings.cartDiscount && <span>{strings.cartDiscount}</span>}
        <span className="text-gray-700">
          {title} {quantity && `(x${quantity})`}
        </span>
      </div>

      <span className="shrink-0">-{amountWithCurrency}</span>
    </div>
  )
}

export default CartDiscount
