import cx from 'classnames'
import NextImage from 'next/image'
import { type CSSProperties, forwardRef, useState } from 'react'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { borderRadiusClassMap } from '@lib/border'
import {
  type ImageDimensions,
  getImageDimensions,
  getSanityImageLoader,
} from '@lib/image'

import Link from '@components/link'

interface PhotoProps {
  image: SanityImageFragment
  width?: number
  height?: number
  sizes?: string
  fill?: boolean
  priority?: boolean
  unoptimized?: boolean
  quality?: number
  className?: string
  imageClassName?: string
  style?: CSSProperties
  imageStyle?: CSSProperties
  showCaption?: boolean
  showPlaceholder?: boolean
  fadeIn?: boolean
}

const Photo = forwardRef<HTMLElement, PhotoProps>(
  (
    {
      image,
      width,
      height,
      sizes,
      fill,
      priority,
      unoptimized,
      quality = 75,
      className,
      imageClassName,
      style,
      imageStyle,
      showCaption = true,
      showPlaceholder = true,
      fadeIn,
    },
    ref,
  ) => {
    const [isLoaded, setIsLoaded] = useState(false)

    if (!image || !image.asset) {
      return null
    }

    const dimensions: ImageDimensions | undefined = !fill
      ? getImageDimensions(image, width, height)
      : undefined

    const placeholder = showPlaceholder
      ? (image.asset.metadata.lqip as `data:image/${string}`)
      : undefined

    const figure = (
      <figure ref={ref} className={cx('photo', className)} style={style}>
        <NextImage
          src={JSON.stringify(image)}
          loader={getSanityImageLoader}
          width={dimensions?.width}
          height={dimensions?.height}
          sizes={sizes}
          fill={!!fill}
          unoptimized={!!unoptimized}
          priority={!!image.priority || !!priority}
          quality={quality}
          placeholder={placeholder}
          alt={image.alt ?? ''}
          className={cx(
            'block overflow-hidden transition-opacity duration-500',
            image.borderRadius
              ? borderRadiusClassMap[image.borderRadius ?? '']
              : '',
            {
              'opacity-0': fadeIn && !isLoaded,
            },
            imageClassName,
          )}
          style={imageStyle}
          onLoad={() => setIsLoaded(true)}
        />

        {showCaption && image.caption && (
          <figcaption className="text-current text-sm my-2">
            {image.caption}
          </figcaption>
        )}
      </figure>
    )

    if (image.link) {
      return (
        <Link link={image.link} className="no-underline">
          {figure}
        </Link>
      )
    }

    return figure
  },
)

Photo.displayName = 'Photo'

export default Photo
