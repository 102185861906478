import {
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { useUrlParameters } from './hooks'

interface SearchContextDrawer {
  isOpen: boolean
}

interface SearchContextProps {
  queryInputValue: string
  setQueryInputValue: (newQuery: string) => void
  querySuggestionValue?: string
  setQuerySuggestionValue: (newQuerySuggestionValue: string) => void
  queryUrlParameterValue?: string
  setQueryUrlParameterValue: (newQueryUrlParameterValue: string) => void
  searchResultsLoaded: boolean
  setSearchResultsLoaded: (newSearchResultsLoaded: boolean) => void
  searchDrawer: SearchContextDrawer
  toggleSearchDrawer: (isOpen: boolean) => void
}

interface SearchContextProviderProps {
  children: ReactNode
}

export const SearchContext = createContext<SearchContextProps>({
  queryInputValue: '',
  setQueryInputValue: () => null,
  setQuerySuggestionValue: () => null,
  setQueryUrlParameterValue: () => null,
  searchResultsLoaded: false,
  setSearchResultsLoaded: () => null,
  searchDrawer: {
    isOpen: false,
  },
  toggleSearchDrawer: () => null,
})

export const SearchContextProvider = ({
  children,
}: SearchContextProviderProps) => {
  const [searchResultsLoaded, setSearchResultsLoaded] = useState(false)
  const [searchDrawer, setSearchDrawer] = useState<SearchContextDrawer>({
    isOpen: false,
  })
  const [queryInputValue, setQueryInputValue] = useState<string>('')
  const [querySuggestionValue, setQuerySuggestionValue] = useState<string>()
  const [queryUrlParameterValue, setQueryUrlParameterValue] = useState<string>()

  // Get initial query on page load
  const [urlParameters, updateUrlParameters] = useUrlParameters([
    {
      name: 'query',
    },
  ])
  useEffect(() => {
    const queryUrlParameter = urlParameters.find(
      (urlParameter) => urlParameter.name === 'query',
    )
    setQueryUrlParameterValue(queryUrlParameter?.value ?? undefined)
  }, [urlParameters])

  const toggleSearchDrawer = useCallback((isOpen: boolean) => {
    setSearchDrawer({
      isOpen,
    })
  }, [])

  return (
    <SearchContext.Provider
      value={{
        queryInputValue,
        setQueryInputValue,
        querySuggestionValue,
        setQuerySuggestionValue,
        queryUrlParameterValue,
        setQueryUrlParameterValue,
        searchResultsLoaded,
        setSearchResultsLoaded,
        searchDrawer,
        toggleSearchDrawer,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
