import cx from 'classnames'
import { useContext } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSubtotalProps {
  price: number
  className?: string
}

const CartSubtotal = ({ price, className }: CartSubtotalProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className,
      )}
    >
      <span>{strings.cartSubtotal}</span>
      <span className="shrink-0">
        {getPriceWithCurrency(price, currency, {
          taxRate,
          thousandSeparator: ',',
        })}
      </span>
    </div>
  )
}

export default CartSubtotal
