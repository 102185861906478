import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import {
  type SanityProductFragment,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { LanguageContext } from './language-context'
import { useActiveVariant, useProductWithInventory } from './product'

interface ProductContextQuickAddModal {
  isOpen: boolean
  product?: SanityProductFragment
}

interface ProductContextProps {
  product?: SanityProductFragment
  setProduct: (product: SanityProductFragment) => void
  activeVariant?: SanityProductVariantFragment
  onVariantChange: (variantId: number) => void
  quickAddModal: ProductContextQuickAddModal
  toggleQuickAddModal: (
    isOpen: boolean,
    product?: SanityProductFragment,
  ) => void
}

interface StringsContextProviderProps {
  children: ReactNode
}

export const ProductContext = createContext<ProductContextProps>({
  setProduct: () => null,
  onVariantChange: () => null,
  quickAddModal: {
    isOpen: false,
  },
  toggleQuickAddModal: () => null,
})

export const ProductContextProvider = ({
  children,
}: StringsContextProviderProps) => {
  const { locale } = useContext(LanguageContext)

  const [product, setProduct] = useState<SanityProductFragment>()
  const [quickAddModal, setQuickAddModal] =
    useState<ProductContextQuickAddModal>({
      isOpen: false,
    })

  // Get active product and variant
  const productWithInventory = useProductWithInventory(locale, product)
  const [activeVariant, onVariantChange] =
    useActiveVariant(productWithInventory)

  const toggleQuickAddModal = useCallback(
    (isOpen: boolean, product?: SanityProductFragment) => {
      setQuickAddModal({
        isOpen,
        product,
      })
    },
    [],
  )

  return (
    <ProductContext.Provider
      value={{
        product: productWithInventory,
        setProduct,
        activeVariant,
        onVariantChange,
        quickAddModal,
        toggleQuickAddModal,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
