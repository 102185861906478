import axios, { type AxiosResponse } from 'axios'
import { type SessionOptions } from 'iron-session'
import { useCallback, useContext } from 'react'
import useSWR from 'swr'

import {
  type SanityCompany,
  type SanityCustomer,
} from '@data/sanity/queries/types/customer'
import { CountryCode } from '@data/shopify/admin/types'
import { type ParseResults, ParseStatus } from './shopify/graphql/client'
import { type Locale } from './language'
import { LanguageContext } from './language-context'
import { type UserAddress, type UserProduct } from './user'

export interface SignupFormValues {
  firstName: string
  lastName: string
  company: string
  vatNumber: string
  address: string
  postalCode: string
  city: string
  countryCode: CountryCode
  email: string
  phone: string
  password: string
  repeatPassword: string
  subscribeToNewsletter?: boolean
}

export interface LoginFormValues {
  email: string
  password: string
}

export interface PasswordRecoveryFormValues {
  email: string
}

export type UserCustomer = Pick<
  SanityCustomer,
  'firstName' | 'lastName' | 'email' | 'phone'
>

export type UserCompany = Pick<
  SanityCompany,
  | 'name'
  | 'vatNumber'
  | 'email'
  | 'contactPerson'
  | 'country'
  | 'percentDiscount'
>

export interface StoredUser {
  isLoggedIn: boolean
  email?: string
  token?: string
  addresses?: UserAddress[]
  customer?: UserCustomer
  company?: UserCompany
}

export interface UserSession {
  user?: StoredUser
}

export type ApiAuthResponse = ParseResults & {
  user?: StoredUser
}

interface LogoutPayload {
  token?: string
}

export const sessionOptions: SessionOptions = {
  password: {
    1: process.env.SECRET_COOKIE_PASSWORD ?? '',
  },
  cookieName: 'adj_auth',
}

/**
 * User data and mutation hook.
 */
export const useUser = () => {
  const { locale } = useContext(LanguageContext)

  const { data: user, mutate: mutateUser } = useSWR<StoredUser>(
    {
      url: '/api/auth/user',
      localeHeader: locale,
    },
    async ({ url, localeHeader }: { url: string; localeHeader: Locale }) => {
      const response = await axios.get<StoredUser>(url, {
        headers: {
          'X-Locale': localeHeader,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    },
  )

  return {
    user,
    mutateUser,
  } as const
}

/**
 * Returns a method that logs in a user.
 */
export const useLoginUser = () => {
  const { locale } = useContext(LanguageContext)
  const { mutateUser } = useUser()

  return useCallback(
    async (payload: LoginFormValues) => {
      try {
        const apiLoginResponse = await axios.post<
          ApiAuthResponse,
          AxiosResponse<ApiAuthResponse>,
          LoginFormValues
        >('/api/auth/login', payload, {
          headers: {
            'Content-Type': 'application/json',
            'X-Locale': locale,
          },
        })
        const { user, ...parseResults } = apiLoginResponse.data
        mutateUser(user)

        return parseResults
      } catch (error) {
        console.log(error)

        return {
          fieldErrors: {},
          errorCount: 0,
          status: ParseStatus.UNKNOWN_ERROR,
        }
      }
    },
    [locale, mutateUser],
  )
}

/**
 * Returns a method that logs out a user.
 */
export const useLogoutUser = () => {
  const { user, mutateUser } = useUser()
  const { locale } = useContext(LanguageContext)

  return useCallback(
    async (onSuccess?: () => void) => {
      const payload: LogoutPayload = { token: user?.token }
      const apiLogoutResponse = await axios.post<
        ApiAuthResponse,
        AxiosResponse<ApiAuthResponse>,
        LogoutPayload
      >('/api/auth/logout', payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      })

      // Update SWR cache and redirect to the login page
      mutateUser(apiLogoutResponse.data.user)

      if (onSuccess) {
        onSuccess()
      }
    },
    [locale, mutateUser, user?.token],
  )
}
