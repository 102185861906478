import cx from 'classnames'
import { useContext } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface ProductPriceProps {
  price: number
  comparePrice?: number
  variant?: 'sm' | 'lg'
  showOldPrice?: boolean
  showDiscountPercent?: boolean
  className?: string
}

const ProductPrice = ({
  price,
  comparePrice,
  variant,
  showOldPrice,
  showDiscountPercent,
  className,
}: ProductPriceProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const comparePriceDiscountPercent = comparePrice
    ? Math.ceil(((comparePrice - price) / comparePrice) * 100)
    : undefined

  return (
    <div
      className={cx(
        'inline-flex shrink-0 flex-wrap items-center gap-x-3 gap-y-1 leading-normal',
        {
          'font-medium': variant !== 'sm',
          'text-sm': variant === 'sm',
          'text-lg': variant === 'lg',
        },
        className,
      )}
    >
      <span className={cx()}>
        {getPriceWithCurrency(comparePrice ?? price, currency, {
          taxRate,
          thousandSeparator: ',',
        })}
      </span>

      {!!comparePrice && !!price && showOldPrice && (
        <span
          className={cx('line-through', {
            'text-sm': !variant,
            'text-xs': variant === 'sm',
            'text-base': variant === 'lg',
          })}
        >
          {getPriceWithCurrency(price, currency, {
            taxRate,
            thousandSeparator: ',',
          })}
        </span>
      )}

      {!!comparePriceDiscountPercent && showDiscountPercent && (
        <span
          className={cx({
            'text-sm': !variant,
            'text-xs': variant === 'sm',
            'text-base': variant === 'lg',
          })}
        >
          {strings.productDiscountText.replace(
            /{percent}/gi,
            comparePriceDiscountPercent.toString(),
          )}
        </span>
      )}
    </div>
  )
}

export default ProductPrice
