import {
  type AttributeInput,
  type CartFragmentFragment,
} from '@data/shopify/storefront/types'
import { type Cart, type CartFormValues, type LineItem } from '@lib/cart/types'
import { type Locale } from '@lib/language'
import { getSanityClient } from '@lib/sanity/client'
import { getProductVariants } from '@lib/sanity/product-variant'
import { parseShopifyGlobalId } from './client'
import { type CartFragmentFragmentLine } from './graphql/cart'
import { shopifyFrameProductType } from '@frame-builder/lib/shopify/product'

export const shopifyAttributeTaxVatId = '_vat_id'
export const shopifyAttributeTaxExempt = '_tax_exempt'

/**
 * Gets tax-related cart attributes.
 */
export const getTaxCartAttributes = (
  cartFormValues: CartFormValues,
  taxExempt: boolean,
) => {
  const customAttributes: AttributeInput[] = []

  if (cartFormValues.vatId) {
    // Set VAT ID checkout attribute
    customAttributes.push({
      key: shopifyAttributeTaxVatId,
      value: cartFormValues.vatId,
    })

    // Set tax exemption checkout attribute
    customAttributes.push({
      key: shopifyAttributeTaxExempt,
      value: taxExempt ? 'yes' : 'no',
    })
  }

  return customAttributes
}

/**
 * Parses Shopify line items.
 */
const parseShopifyCartLineItems = async (
  lineItems: CartFragmentFragmentLine[],
  locale: Locale,
): Promise<LineItem[]> => {
  // Read product variants in cart
  const cartVariantIds = lineItems
    .map((lineItem) =>
      lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null,
    )
    .filter(Boolean) as number[]
  const sanityClient = getSanityClient()
  const productVariants = await getProductVariants(
    sanityClient,
    locale,
    cartVariantIds,
  )

  return lineItems
    .map((lineItem) => {
      const variantId = lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null

      // Parse frame products (which do not have product data in Sanity)
      if (
        lineItem.merchandise.product.productType === shopifyFrameProductType
      ) {
        const productId = lineItem.merchandise.product.id
          ? parseShopifyGlobalId(`${lineItem.merchandise.product.id}`)
          : null
        const imageUrl = lineItem.merchandise.image?.url as string | undefined

        if (!variantId || !productId) {
          return
        }

        const frameVariantLineItem: LineItem = {
          lineId: lineItem.id,
          quantity: lineItem.quantity,
          variantID: variantId,
          title: lineItem.merchandise.title,
          imageUrl,
          // Convert from major units to minor units
          price: parseFloat(lineItem.merchandise.price.amount) * 100,
          product: {
            productID: productId,
            title: lineItem.merchandise.product.title,
            // Used only to link cart item to a product page
            slug: {
              current: '',
            },
          },
          // Used only when selecting which cart image to show
          options: [],
        }
        return frameVariantLineItem
      }

      const variant = variantId
        ? productVariants.find(
            (productVariant) => productVariant.variantID === variantId,
          )
        : null

      if (!variant) {
        return
      }

      const variantLineItem: LineItem = {
        lineId: lineItem.id,
        quantity: lineItem.quantity,
        ...variant,
      }
      return variantLineItem
    })
    .filter(Boolean) as LineItem[]
}

/**
 * Parses Shopify cart.
 */
export const parseShopifyCart = async (
  locale: Locale,
  shopifyCart?: CartFragmentFragment,
): Promise<Cart | undefined> => {
  if (!shopifyCart) {
    return
  }

  const lineItems = await parseShopifyCartLineItems(
    shopifyCart.lines.edges.map((edge) => edge.node),
    locale,
  )

  const subtotal = shopifyCart.estimatedCost.subtotalAmount
    ? Number(shopifyCart.estimatedCost.subtotalAmount.amount) * 100
    : 0

  const automaticDiscount = shopifyCart.lines.edges.reduce(
    (result, edge) => {
      const discountAllocation = edge.node.discountAllocations[0]

      if (
        discountAllocation &&
        'title' in discountAllocation &&
        'discountedAmount' in discountAllocation
      ) {
        return {
          title: discountAllocation.title,
          amount:
            result.amount +
            parseFloat(discountAllocation.discountedAmount.amount) * 100,
        }
      }

      return result
    },
    {
      title: '',
      amount: 0,
    },
  )

  const total = subtotal - automaticDiscount.amount

  return {
    id: shopifyCart.id,
    lineItems,
    automaticDiscount,
    discountCodes: shopifyCart.discountCodes.map(({ code }) => code),
    subtotal,
    total,
    webUrl: `${shopifyCart.checkoutUrl}`,
  }
}
