import {
  type NormalizedCacheObject,
  InMemoryCache,
  ApolloClient,
} from '@apollo/client'

import { CustomerErrorCode } from '@data/shopify/storefront/types'
import { type ErrorMessages } from '@lib/helpers'

export type ShopifyClient = ApolloClient<NormalizedCacheObject>

export enum ParseStatus {
  OK = 'ok',
  INVALID_CREDENTIALS = 'invalid_credentials',
  MISSING_CREDENTIALS = 'missing_credentials',
  MISSING_COMPANY = 'missing_company',
  UNKNOWN_ERROR = 'unknown_error',
}

export interface ParseResults {
  fieldErrors: ErrorMessages
  errorCount: number
  status: ParseStatus
}

interface CustomerUserError {
  __typename?: 'CustomerUserError'
  code?: CustomerErrorCode | null
  field?: string[] | null
  message: string
}

/**
 * Returns apollo client for Shopify Storefront GraphQL API.
 */
export const getShopifyStorefrontClient = (
  shopifyStorefrontApiUrl: string,
  shopifyStorefrontAccessToken: string,
) =>
  new ApolloClient({
    uri: `${shopifyStorefrontApiUrl}/graphql.json`,
    cache: new InMemoryCache(),
    headers: shopifyStorefrontAccessToken
      ? {
          'X-Shopify-Storefront-Access-Token': shopifyStorefrontAccessToken,
        }
      : {},
  })

/**
 * Returns apollo client for Shopify Admin GraphQL API.
 */
export const getShopifyAdminClient = (
  shopifyAdminApiUrl: string,
  shopifyAdminAccessToken: string,
) =>
  new ApolloClient({
    uri: `${shopifyAdminApiUrl}/graphql.json`,
    cache: new InMemoryCache(),
    headers: shopifyAdminAccessToken
      ? {
          'X-Shopify-Access-Token': shopifyAdminAccessToken,
        }
      : {},
  })

/**
 * Gets field name from Shopify GraphQL API result error.
 */
export const getGrqphQLErrorFieldName = (errorField?: string[] | null) => {
  const count = errorField?.length ?? 0

  if (errorField && count > 0) {
    return errorField[count - 1]
  }
}

/**
 * Gets error field validation results.
 */
export const parseMutationResult = (
  customerUserErrors: CustomerUserError[],
): ParseResults => {
  const results: ParseResults = {
    fieldErrors: {},
    errorCount: customerUserErrors.length,
    status: ParseStatus.OK,
  }

  customerUserErrors.forEach((customerUserError) => {
    const fieldName = getGrqphQLErrorFieldName(customerUserError.field)

    if (fieldName) {
      results.fieldErrors[fieldName] = customerUserError.message
    }
  })

  return results
}
