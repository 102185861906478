interface PriceOptions {
  discountRate?: number
  taxRate?: number
  hasTrailingZeros?: boolean
  thousandSeparator?: string
  minorUnitScale?: number
}

/**
 * Gets formatted date by date string and locale.
 */
export const getFormattedDate = (
  date: string,
  locale: string,
  formatOptions?: Intl.DateTimeFormatOptions,
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    locale,
    formatOptions ?? {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  )

  return dateTimeFormat.format(new Date(date))
}

/**
 * Formats a value by adding thousands separators.
 */
export const addThousandSeparators = (
  value: string,
  thousandSeparator: string,
) => {
  if (!thousandSeparator) {
    return value
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
}

/**
 * Gets price from value in minor units. Adds tax and trailing zeros, if needed.
 */
export const getPrice = (minorUnits: number, priceOptions?: PriceOptions) => {
  const discountRate = priceOptions?.discountRate ?? 0
  const taxRate = priceOptions?.taxRate ?? 0
  const hasTrailingZeros = !!priceOptions?.hasTrailingZeros
  const thousandSeparator = priceOptions?.thousandSeparator ?? ''
  const minorUnitScale = priceOptions?.minorUnitScale ?? 2

  // Convert price from minor to major units
  let price = minorUnits / Math.pow(10, minorUnitScale)

  // Apply discount
  if (discountRate !== 0) {
    price = price * (1 - discountRate)
  }

  // Apply tax
  if (taxRate !== 0) {
    price = price * (1 + taxRate)
  }

  if (!hasTrailingZeros && price % 1 === 0) {
    return addThousandSeparators(`${price}`, thousandSeparator)
  }

  const priceParts = price.toFixed(minorUnitScale).split('.')
  priceParts[0] = addThousandSeparators(priceParts[0], thousandSeparator)

  return `${priceParts.join('.')}`
}

/**
 * Gets price with currency from minor units. Adds tax and trailing zeros, if needed.
 */
export const getPriceWithCurrency = (
  minorUnits: number,
  currency?: string,
  priceOptions?: PriceOptions,
) => {
  const price = getPrice(minorUnits, priceOptions)

  return currency ? `${price} ${currency}` : price
}
